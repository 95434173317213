import React from 'react';
import { Box, Typography, Divider, Button } from '@mui/material';

const ProjectOverlay = ({ project, onClose }) => {
  if (!project) return null; // Avoid rendering without a project

  return (
    <Box
      sx={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.default,
        zIndex: theme.zIndex.modal,
        overflowY: 'auto',
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.text.primary,
      })}
    >
      <Button
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          top: theme.spacing(2),
          left: theme.spacing(2),
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        })}
      >
        Back
      </Button>

      <Box sx={{ mt: (theme) => theme.spacing(8), textAlign: 'center' }}>
        <Typography variant="h3" sx={{ mb: (theme) => theme.spacing(2) }}>
          {project.name}
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({
            marginTop: theme.spacing(2),
            color: theme.palette.text.secondary,
          })}
        >
          {project.longDescription}
        </Typography>
      </Box>

      <Divider sx={(theme) => ({ my: theme.spacing(4), borderColor: theme.palette.divider })} />

      <Box>
        <Typography
          variant="h6"
          sx={(theme) => ({
            marginBottom: theme.spacing(2),
            color: theme.palette.text.primary,
          })}
        >
          Process Content
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
        >
          Here’s where you can showcase your process for this project. Add images, details, or any other content you want.
        </Typography>
      </Box>
    </Box>
  );
};

export default ProjectOverlay;
